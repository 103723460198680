const STREAMING_LINKS = [
  {
    src: "/assets/icons/spotify-icon.png",
    alt: "Spotify",
    href: "https://open.spotify.com/artist/4DAcJXcjX0zlQAZAPAx4Zb?si=Vr805J8HSYeXNb0FcAvfHw",
  },
  {
    src: "/assets/icons/apple-music-icon.png",
    alt: "Apple Music",
    href: "https://music.apple.com/us/artist/ben-ben/1215052739",
  },
  {
    src: "/assets/icons/youtube-icon.png",
    alt: "Youtube Music",
    href: "https://youtu.be/xifzlRqt0qo",
  },
];

const ABOUT_TEXT = `A 9-piece band from the Philippines, composed of twin brothers Miguel Benjamin and Paolo Benjamin on acoustic guitars and vocals • Poch Barretto on electric guitar • Keifer Cabugao on violin • Andrew De Pano and Toni Muñoz on percussion • Pat Lasaten on keyboards • Agnes Reoma on bass • Jam Villanueva on drums
<br/> <br/>
Ben&Ben has endeared itself to many with their heartfelt lyrics, unique musicality, and their electric vibe onstage. In the prime of their youth, the band is keen on dedicating themselves to bringing richness to the music they create through songs of hope, love and positivity. `;

const LYRICS = `<h1 className='text-lg font-medium uppercase tracking-wider mb-5'>The ones we once loved</h1>

Saw you singing your song the other day<br/>
It's been a long time since your eyes lit up that way<br/>
I felt the guilt build up as I looked back how things went wrong<br/>
Did I have to sweep in and scar you? 
<br /><br />
Five years, we shared the bittersweetness of our youth<br/>
Then we broke up, because sometimes it is the best thing to do<br/>
Did it ever cross your mind that maybe you hurt me too?<br/>
But i'm not taking it against you 
<br /><br />
Since the beginning I never wanted anything but to see you reach your dreams<br/>
I knew that you just wanted me<br/>
But I had dreams of my own, and I just couldn't let them go
<br /><br />
So I apologize<br/>
For coming into your life<br/>
Just to break your heart to pieces<br/>
And then leave you in the night 
<br /><br />
I tried my best to stay by your side<br/>
But who knew the rolling seasons would reveal that sometimes<br/>
We aren't meant to be the one<br/>
So goodbye to the ones we once loved 
<br /><br />
When things got heavy, and you expected more from me<br/>
I tried to be there to take away your tears<br/>
But I didn't notice that I was chipping away<br/>
At pieces of myself until there was nothing left to give<br/>
I didn't even want to live 
<br /><br />
But I apologize<br/>
For coming into your life<br/>
Just to break your heart to pieces<br/>
And then leave you in the night 
<br /><br />
I tried my best to stay by your side<br/>
But who knew the rolling seasons would reveal that sometimes<br/>
We aren't meant to be the one<br/>
So goodbye to the ones we once loved 
<br /><br />
I realized it wasn't anyone's fault at all<br/>
It's just that I wasn't built to build you up when you fall<br/>
Maybe we were meant to be a lesson to each other<br/>
So I wish you well<br/>
And I'm sorry 
<br /><br />
Oh I apologize<br/>
For coming into your life<br/>
Just to break your heart to pieces<br/>
And then leave you in the night 
<br /><br />
I tried my best to stay by your side<br/>
But who knew the rolling seasons would reveal that sometimes<br/>
We aren't meant to be the one<br/>
So goodbye to the ones<br/>
I wasn't meant to be the one<br/>
So goodbye to the one I once loved`;

const SOCIAL_ICON = [
  { src: "/assets/icons/ig-icon.svg", alt: "Instagram" },
  { src: "/assets/icons/link-icon.svg", alt: "Link" },
  { src: "/assets/icons/twitter-icon.svg", alt: "Twitter" },
  { src: "/assets/icons/fb-icon.svg", alt: "Facebook" },
];
const ONBOARDING_LOADING_TIME = 2000;

const SYSTEM_MODULES = [
  {
    name: "Dashboard",
    logoURL: "/assets/icons/dashboard-icon.svg",
    routes: "/app/dashboard",
  },
  {
    name: "Postcards",
    logoURL: "/assets/icons/medical-records.svg",
    routes: "/app/postcards",
  },
  {
    name: "Users",
    logoURL: "/assets/icons/leads-icon.svg",
    routes: "/app/users",
  },
  {
    name: "Logout",
    logoURL: "/assets/icons/logout-icon.svg",
    routes: "/",
    /* funcLogOut: (e) => {
      e.preventDefault();
      signOut({
        callbackUrl: "/",
      });
    }, */
  },
];

export const ERROR_OCCURED_MSG = 'An error occurred. Please contact your system administrator.';

const BAD_WORDS = ['puta','tanga','gago','shet','pakyu','fuck','pakshet','titi','kiki','puke','betlog',"sex","iyot","kantot"]

const SEO = {
  title: "The Ones We Once Loved",
  description: "The ones we once loved",
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/assets/bblogo.png",
    },
  ],
};
export {
  STREAMING_LINKS,
  ABOUT_TEXT,
  LYRICS,
  SEO,
  SOCIAL_ICON,
  ONBOARDING_LOADING_TIME,
  SYSTEM_MODULES,
  BAD_WORDS,
};
