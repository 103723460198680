import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { DefaultSeo } from "next-seo";

import { SEO } from "@src/constant";
import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <DefaultSeo {...SEO} />
        <Component {...pageProps} />
    </QueryClientProvider>
  );
}

export default MyApp;
